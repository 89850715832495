.carousel {
    height: 600px;
}

.carousel .slide .wrapper {
    background: linear-gradient(180deg, rgba(0,0,0,0) 70%, rgba(0,0,0,1) 100%);
    height: 100%;
}

.slide {
    width: 100%;
    height: 600px;
    background-repeat: no-repeat!important;
    background-position: center!important;
    background-size: cover!important;
    position: absolute;
    opacity: 0;
    transition: all .3s ease-out;
    z-index: 1;
    overflow: hidden;
}

.slide.active {
    opacity: 1;
    z-index: 9;
}

.slide .slide-image {
    width: 100%;
    height: 600px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform: scale(1.5);
    background-repeat: no-repeat!important;
    background-position: center!important;
    background-size: cover!important;
}

.slide.active .slide-image {
    animation-name: estirar;
    animation-duration: 240s;
}

@keyframes estirar {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.5);
    }
}


.slide .text {
    width: 70%;
    color: #fff;
    position: absolute;
    padding-top: 100px;
    padding-left: 50px;
}

.slide .text .title {
    font-family: 'Oswald', sans-serif;
    font-size: 40px;
    font-weight: 300;
    clear: both;
}

.slide .text .description {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    width: 100%;
}

.slide .text .date {
    background-color: rgba(0, 0, 0, .7);
    padding: 10px;
    width: auto;
    display: inline-block;
}

.slide .text .date .separator {
    display: inline;
    margin: 0px 3px;
}


.slide .text .more-info {
    background: #3F9DCA;
    color: #fff;
    padding: 10px;
    margin-top: 20px;
    display: inline-block;
    text-transform: uppercase;
    border: none;
    text-decoration: none;
}

.slide.active .wrapper .width-wrapper {
    z-index: 999;
}

.slide .text .calendar-icon {
    width: 24px;
    float: left;
    margin-right: 10px;
}

div[class*="makeStyles-copyContainer"], .jss5 {
    display: none!important;
}

.social {
    margin-top: 10px;
}

.social button {
    margin-right: 10px;
}

@media (min-width: 1280px) {
    .slide.active .slide-image {
        animation-duration: 240s;
        z-index: -1;
    }

    .carousel .slide .wrapper {
        background: linear-gradient(180deg, rgba(0,0,0,0) 70%, rgba(0,0,0,1) 100%);
    }

    .slide .text {
        width: 70%;
        position: absolute;
        padding-top: 200px;
        padding-left: 50px;
    }

    .slide .text .title {
        font-size: 58px;
        text-shadow: 4px 4px rgba(0, 0, 0, .5);
    }
    
    .slide .text .description {
        width: 100%;
        font-weight: 300;
    }
}