.activities {
    clear: both;
    z-index: 9;
}

.activities .title {
    font-family: 'Oswald';
    color: #fff;
    font-size: 24px;
    font-weight: 300;
}

/*VIEW*/
.activities .items {
    margin: 0 auto;
    width: 100%;
    position: relative;
    transition: all .3s;
    scroll-behavior: smooth;
}

.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

.activities.horizontal .items {
    display: block;
    white-space: nowrap;
    height: 100%;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.activities .item {
    float:left;
    margin: 10px 5px;
    height: 220px;
    width: 300px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    border: solid 1px #000;
    transition: all .3s;
}

.activities.horizontal .item {
    height: 170px;
    float: none;
}

.activities .item .image {
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    height: 100%;
    transition: all .3s;
}

.activities .item:hover{
    border: solid 1px #333;
    transform: scale(1);
    z-index: 999;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, .9);
}

.activities.horizontal .item:hover{
    transform: scale(1);
}

.activities .item:hover .image{
    transform: scale(1.2);
}

@keyframes agrandar {
    from {transform: scale(1.2)}
    to {transform: scale(2)}
}

.activities .item .content {
    background: rgba(0, 0, 0, .8);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9;
    top: 85%;
    left: 0;
    padding: 5px 10px;
    transition: all .3s;
}

.activities.horizontal .item .content {
    top: 80%;
}

.activities .item:hover .content{
    top: 0px;
}

.activities .item .content .title {
    color: #fff;
    margin-top: 0px;
    font-size: 1.1em;
    float: left;
    overflow: hidden;
    max-width: 57%;
    white-space: break-spaces;
}

.activities .item .content .calendar-icon {
    width: 18px;
    float: left;
    margin-right: 10px;
}

.activities .item .content .date {
    color: #fff;
    margin-top: 0px;
    font-size: .8em;
    float: right;
    margin-right: 20px;
    margin-top: 8px;
}

.activities .item .content .date.end {
    margin-top: 0px;
}

.activities .item .content .description {
    clear: both;
    color: #fff;
    margin: 0px;
    font-size: .8em!important;
    white-space: break-spaces;
    padding-right: 5px;
}

.activities .item .content .footer {
    position: absolute;
    bottom: 20px;
    width: 95%;
    padding: 0px;
    border: none;
    background: transparent;
}

.activities .item .content .footer .price {
    color: #fff;
    float: left;
    font-size: .9em;
    margin: 0px;
}

.activities .item .content .footer .more-info {
    color: #fff;
    float: right;
    margin-right: 20px;
    text-decoration: none;
    font-size: .9em;
}

.activities.horizontal .item .content .description,
.activities.horizontal .item .content .footer .price, 
.activities.horizontal .item .content .footer .more-info {
    font-size: .7em;
}

@media(min-width: 1280px) {
    .activities {
        margin-left: 0px;
    }
    
    .activities .item {
        margin: 5px;
        width: 350px;
    }
    .activities .item:hover{
        transform: scale(1.2);
    }

    .activities.horizontal .items {
        width: 1254px;
    }

    .activities.horizontal .item {
        float: none;
        display: inline-block;
        width: 300px;
    }
}