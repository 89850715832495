.footer {
    padding: 20px 0px;
    clear: both;
    width: 100%;
    margin: 40px auto auto auto;
    background: #111;
}

.footer .contact, .footer .social {
    width: 100%;
    float: left;
    height: 200px;
    color: #ddd;
}

.footer .contact * {
    color: #ddd;
    text-decoration: none;
    font-size: .95em;
}

.footer .contact>div, .footer .social>div {
    width: 200px;
    margin: 0 auto;
}

.footer .copyright{
    clear: both;
    text-align: center;
    border-top: solid 1px #202020;
    color: #ddd!important;
}

.footer .social a {
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
    color: #ddd;
}

.footer .title {
    font-family: 'Oswald';
    font-size: 24px;
    font-weight: 300;
}

@media(min-width: 1280px) {
    .footer .contact, .footer .social {
        width: 50%;
    }   
}