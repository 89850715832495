.gallery {
    clear: both;
    margin-left: -10px;
}

.gallery-full .gallery {
    overflow: hidden;
}

.gallery .card {
    float: left;
    width: 278px;
    height: 150px;
    margin: 5px;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    background-position: center!important;
    position: relative;
    overflow: hidden;
}

.gallery-full .gallery .card {
    width: 100%;
    height: 300px;
}

.gallery .card.full-width {
    float: none;
    margin: 0 auto;
}

.gallery .card:hover {
    cursor: pointer;
}

.gallery .card .description {
    position: absolute;
    bottom: 0px;
    background: rgba(0, 0, 0, .8);
    color: #ddd;
    padding: 10px;
    width: 100%;
}


  
@media (min-width: 1280px) {
    .gallery {
        margin-left: 0px;
    }
}