.categories{
    width: 100%;
    margin: 0 auto;
    position: relative;
    top: -180px;
    z-index: 99;
    height: 100%;
}

.categories .category {
    color: #fff;
    padding: 10px;
}

.categories .category .card {
    text-align: center;
    width: 350px;
    height: 160px;
    border: solid 1px #333;
    border-radius: 5px;
    transition: transform .3s;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 24px;
    background-repeat: no-repeat;
    background-size: cover!important;
    background-position: center!important;
    position: relative;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 60%);
    overflow: hidden;
    line-height: 160px;
    margin: 0 auto;
}

.categories .category .card .wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.categories .category .card a {
    position: absolute;
    left: 0%;
    width: 100%;
    display: inline-block;
    background: rgba(0, 0, 0, .3);
    color: #fff;
    text-decoration: none;
}

.categories .category .card video {
    position: absolute;
    opacity: 0;
    left: 0;
    width: 100%;
    transition: all 1s;
    background: transparent;
}

.categories .category .card:hover video {
    opacity: 1;
}

@media (min-width: 1280px) {
    .categories{
        width: 100%;
        margin: 0 auto;
        position: relative;
        top: -80px;
        z-index: 99;
        height: 100%;
    }
    
    .categories .category {
        float: left;
    }
    
    .categories .category .card {
        width: 290px;
    }
    
    .categories .category .card .wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
    
    .categories .category .card a {
        position: absolute;
        left: 0%;
        width: 100%;
        display: inline-block;
        background: rgba(0, 0, 0, .3);
        color: #fff;
        text-decoration: none;
    }
    
    .categories .category .card:hover {
        transform: scale(1.3);
        z-index: 9999;
        border: solid 2px #ddd;
    }
    
    .categories .category .card video {
        position: absolute;
        opacity: 0;
        left: 0;
        width: 100%;
        transition: all 1s;
        background: transparent;
    }
    
    .categories .category .card:hover video {
        opacity: 1;
    }
}