.quienes-somos .image-bg {
    height: 400px;
    width: 100%;
    top: 0;
    left: 0;
    background-position: center!important;
    background-size: cover!important;
    background-repeat: no-repeat!important;
}

.quienes-somos .content {
    z-index: 9;
    color: #fff;
    background: rgba(0, 0, 0, .8);
    padding: 5px 40px;
}

.quienes-somos .title {
    font-family: 'Oswald';
    font-size: 3em;
}

.quienes-somos .video {
    text-align: center;
}

@media (min-width: 1280px) {
    .quienes-somos {
        height: 1500px;
    }

    .quienes-somos.full-height {
        height: 2050px;
    }
    
    .quienes-somos .image-bg {
        height: 1500px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-position: center!important;
        background-size: cover!important;
        background-repeat: no-repeat!important;
    }

    .quienes-somos.full-height .image-bg{
        height: 2100px;
    }
    
    .quienes-somos .content {
        position: relative;
        top: 200px;
        left: 50%;
        z-index: 9;
        color: #fff;
        background: rgba(0, 0, 0, .8);
        padding: 5px 40px;
        width: 70%;
        transform: translateX(-50%);
    }
    
    .quienes-somos .title {
        font-family: 'Oswald';
        font-size: 3em;
    }
    
    .quienes-somos .video {
        text-align: center;
    }
}