body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cookie-panel {
  background-color: rgba(60, 152, 206, .9);
  position: fixed;
  bottom: -100%;
  display: flex;
  justify-content: space-between;
  padding: 30px;
  z-index: 9999;
  transition: all .4s;
}

.cookie-panel.show {
  bottom: 0;
}

.cookie-panel p {
  width: 90%;
  color: #fff;
}

.cookie-panel p a {
  display: inline-block;
  margin-left: 10px;
  color: #fff;
}

.cookie-panel button {
  align-self: center;
  background-color: rgba(60, 152, 206, 1);
  color: #fff;
  border: solid 1px rgb(60, 152, 206);
  padding: 10px;
  box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, .7);
  cursor: pointer;
}
