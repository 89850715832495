@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300;500;700&family=Roboto:wght@100;400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cookie-panel {
  background-color: rgba(60, 152, 206, .9);
  position: fixed;
  bottom: -100%;
  display: flex;
  justify-content: space-between;
  padding: 30px;
  z-index: 9999;
  transition: all .4s;
}

.cookie-panel.show {
  bottom: 0;
}

.cookie-panel p {
  width: 90%;
  color: #fff;
}

.cookie-panel p a {
  display: inline-block;
  margin-left: 10px;
  color: #fff;
}

.cookie-panel button {
  align-self: center;
  background-color: rgba(60, 152, 206, 1);
  color: #fff;
  border: solid 1px rgb(60, 152, 206);
  padding: 10px;
  box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, .7);
  cursor: pointer;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0px;
  padding: 0px;
  background: #000!important;
}

.layout {
  background: #000;
  max-width: 2000px;
  margin: 0 auto;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-2 {
  margin-bottom: 20px;
}

.ml-2 {
  margin-left: 20px;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

img.icon {
  width: 18px;
  position: relative;
  top: 5px;
  margin-right: 20px;
}

.activities-container {
  position: relative;
  top: -180px;
  padding: 0px 30px;
  z-index: 99;
}

.no-top {
  top: 0px;
}

.message {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

.clearfix {
  clear: both;
}

.separator {
  height: 1px;
  clear: both;
  width: 75%;
  margin: 0 auto;
}

.width-wrapper {
  position: relative;
  margin: 0 auto;
} 

.super-title {
  font-family: 'Oswald';
  margin: 20px 30px;
  border-bottom: solid 1px #000;
}

.mini-form {
  margin-left: 30px;
  width: 30%;
}

.medium-form {
  margin-left: 30px;
  width: 50%;
}

.block {
  display: block;
}

a {
  color: #ddd;
  text-decoration: none;
}

a[target='_blank']::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAADSSURBVChTfZE9CsJAEIUzS0A8hAjeIUfQTm3txcoj2Ik5go2CtW2009bOU3gAW0HIj99sYkjMxgeP+dn3lpld8f4gTdOAMM0ri0iyLDNwTtHLeyXOItLBdDHGdLWBLvyKl9D80MNwU5HmJbhhA9dFWQP9IXzGcbxKkuSlWnuTCyomHOHM9/2QsUbkkdNQFSO8UgeM9ia/Nwwc6MvtoBXbZv5SY00aBhbN4KAirsE5kpqKtIHWpdsgLLQg6j+cbMeNCdwy5r7603175MaDMQ8iknwAQrpd0CdufEEAAAAASUVORK5CYII=);
  margin: 0 3px 0 5px
}

.inline {
  display: inline-block;
}

.aligncenter {
  display: block;
  margin: 0 auto;
}

figure {
  margin-left: 0px;
  margin-right: 0px;
}

@media (min-width: 1280px) { 
  .width-wrapper {
    max-width: 1280px;
    width: 1280px;
  }

  .dk-ml-40 {
    margin-left: 40px;
  }

  .dk-ml-50 {
    margin-left: 50px;
  }

  .activities-container {
    top: -50px;
    padding: 0px 50px;
  }
}
.footer {
    padding: 20px 0px;
    clear: both;
    width: 100%;
    margin: 40px auto auto auto;
    background: #111;
}

.footer .contact, .footer .social {
    width: 100%;
    float: left;
    height: 200px;
    color: #ddd;
}

.footer .contact * {
    color: #ddd;
    text-decoration: none;
    font-size: .95em;
}

.footer .contact>div, .footer .social>div {
    width: 200px;
    margin: 0 auto;
}

.footer .copyright{
    clear: both;
    text-align: center;
    border-top: solid 1px #202020;
    color: #ddd!important;
}

.footer .social a {
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
    color: #ddd;
}

.footer .title {
    font-family: 'Oswald';
    font-size: 24px;
    font-weight: 300;
}

@media(min-width: 1280px) {
    .footer .contact, .footer .social {
        width: 50%;
    }   
}
.carousel {
    height: 600px;
}

.carousel .slide .wrapper {
    background: linear-gradient(180deg, rgba(0,0,0,0) 70%, rgba(0,0,0,1) 100%);
    height: 100%;
}

.slide {
    width: 100%;
    height: 600px;
    background-repeat: no-repeat!important;
    background-position: center!important;
    background-size: cover!important;
    position: absolute;
    opacity: 0;
    transition: all .3s ease-out;
    z-index: 1;
    overflow: hidden;
}

.slide.active {
    opacity: 1;
    z-index: 9;
}

.slide .slide-image {
    width: 100%;
    height: 600px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    background-repeat: no-repeat!important;
    background-position: center!important;
    background-size: cover!important;
}

.slide.active .slide-image {
    -webkit-animation-name: estirar;
            animation-name: estirar;
    -webkit-animation-duration: 240s;
            animation-duration: 240s;
}

@-webkit-keyframes estirar {
    from {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    to {
        -webkit-transform: scale(1.5);
                transform: scale(1.5);
    }
}

@keyframes estirar {
    from {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    to {
        -webkit-transform: scale(1.5);
                transform: scale(1.5);
    }
}


.slide .text {
    width: 70%;
    color: #fff;
    position: absolute;
    padding-top: 100px;
    padding-left: 50px;
}

.slide .text .title {
    font-family: 'Oswald', sans-serif;
    font-size: 40px;
    font-weight: 300;
    clear: both;
}

.slide .text .description {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    width: 100%;
}

.slide .text .date {
    background-color: rgba(0, 0, 0, .7);
    padding: 10px;
    width: auto;
    display: inline-block;
}

.slide .text .date .separator {
    display: inline;
    margin: 0px 3px;
}


.slide .text .more-info {
    background: #3F9DCA;
    color: #fff;
    padding: 10px;
    margin-top: 20px;
    display: inline-block;
    text-transform: uppercase;
    border: none;
    text-decoration: none;
}

.slide.active .wrapper .width-wrapper {
    z-index: 999;
}

.slide .text .calendar-icon {
    width: 24px;
    float: left;
    margin-right: 10px;
}

div[class*="makeStyles-copyContainer"], .jss5 {
    display: none!important;
}

.social {
    margin-top: 10px;
}

.social button {
    margin-right: 10px;
}

@media (min-width: 1280px) {
    .slide.active .slide-image {
        -webkit-animation-duration: 240s;
                animation-duration: 240s;
        z-index: -1;
    }

    .carousel .slide .wrapper {
        background: linear-gradient(180deg, rgba(0,0,0,0) 70%, rgba(0,0,0,1) 100%);
    }

    .slide .text {
        width: 70%;
        position: absolute;
        padding-top: 200px;
        padding-left: 50px;
    }

    .slide .text .title {
        font-size: 58px;
        text-shadow: 4px 4px rgba(0, 0, 0, .5);
    }
    
    .slide .text .description {
        width: 100%;
        font-weight: 300;
    }
}
.header {
    position: relative;
    padding: 0px;
}

.logo {
    position: absolute;
    top: 20px;
    left: 28px;
    z-index: 999;
    cursor: pointer;
}

.logo img {
    width: 100px;
}

.menu {
    position: absolute;
    right: 0px;
    z-index: 99;
    top: 20px;
}

.menu a {
    display: block;
    color: #fff;
    font-family: 'Roboto';
    text-decoration: none;
    font-size: .9em;
    padding: 15px;
    font-weight: bold;
    text-transform: uppercase;
    transition: all .3s;
    background: rgba(63, 157, 202, .5);
}

.menu a span{
  display: block;
  background: #fff;
  height: 1px;
  width: 20px;
  margin-bottom: 5px;
}

.menu a:hover {
    background: rgba(63, 157, 202, 1);
    color: #fff;
}

.search {
    position: absolute;
    right: 10px;
    z-index: 999;
    top: 8px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.search button {
    background: transparent;
    border:none;
    z-index: 99;
    margin-right: 20px;
}

.search img {
    width: 16px;
    position: relative;
    top: 3px;
}

.search input {
    background: transparent;
    border: none;
    width: 0px;
    padding: 20px 8px 20px 16px;
    transition: all .3s;
    color: #fff;
    font-size: 18px;
}

.search:hover {
    background: rgba(63, 157, 202, 1);
}

.search.active {
    background: rgba(63, 157, 202, 1);
}

.search:hover input {
    width: 300px;
    padding: 20px 8px 20px 16px;
}

.search input:focus {
    width: 300px;
    padding: 20px 8px 20px 16px;
    outline: none;
}

.lds-ring {
    position: relative;
    width: 80px;
    height: 300px;
    margin: 0 auto;
    padding-top: 300px;
}

.lds-ring.small {
  height: 50px;
  padding-top: 0px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #ccc;
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ccc transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
}

@-webkit-keyframes lds-ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
}

@keyframes lds-ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
}

.loader {
    height: 600px;
    width: 100%;
    display: block;
    text-align: center;
    padding-top: 200px;
}

.loader img {
    width: 300px;
}

.lds-default {
    position: relative;
    width: 80px;
    height: 300px;
    margin: 0 auto;
    padding-top: 300px;
}
  .lds-default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50%;
    -webkit-animation: lds-default 1.2s linear infinite;
            animation: lds-default 1.2s linear infinite;
  }
  .lds-default div:nth-child(1) {
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    top: 37px;
    left: 66px;
  }
  .lds-default div:nth-child(2) {
    -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }
  .lds-default div:nth-child(3) {
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }
  .lds-default div:nth-child(4) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }
  .lds-default div:nth-child(5) {
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }
  .lds-default div:nth-child(6) {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }
  .lds-default div:nth-child(7) {
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }
  .lds-default div:nth-child(8) {
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }
  .lds-default div:nth-child(9) {
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }
  .lds-default div:nth-child(10) {
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }
  .lds-default div:nth-child(11) {
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    top: 62px;
    left: 52px;
  }
  .lds-default div:nth-child(12) {
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }
  @-webkit-keyframes lds-default {
    0%, 20%, 80%, 100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }
  }
  @keyframes lds-default {
    0%, 20%, 80%, 100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }
  }
  
  .sidenav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 9999; /* Stay on top */
    top: 0; /* Stay at the top */
    right: 0;
    background-color: rgba(63, 157, 202, 1); /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  }
  
  /* The navigation menu links */
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-weight: 300;
    color: #fff;
    font-family: 'Oswald';
    display: block;
    transition: 0.3s;
  }
  
  /* When you mouse over the navigation links, change their color */
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  /* Position and style the close button (top right corner) */
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
@media (min-width: 1280px) {
    .loader {
        padding-top: 200px;
    }

    .logo {
        top: 20px;
        left: 28px;
    }
    
    .logo img {
        width: 250px;
    }

    .menu {
        top: 15px;
    }

    .search:hover input, .search input:focus {
        width: 400px;
    }
}
.text-container {
    width: 100%;
    height: 100%;
    position: relative;
    top: -40px;
}

.text-container .title {
    font-family: 'Oswald';
    color: #fff;
    position: relative;
    top: 120px;
    left: 30px;
    font-weight: 300;
    font-size: 3em;
}

.text-container .description {
    font-family: 'Roboto';
    color: #fff;
    position: relative;
    top: 100px;
    padding: 0px 30px;
    font-weight: 400;
    font-size: .9em;
    padding-right: 20px;
}

@media (min-width: 1280px) {
    .text-container {
        height: 200%;
        width: 60%;
        position: relative;
        top: -80px;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
    }
    
    .text-container .title {
        font-family: 'Oswald';
        color: #fff;
        position: relative;
        top: 250px;
        left: 90px;
        font-weight: 300;
        font-size: 5em;
    }
    
    .text-container .description {
        font-family: 'Roboto';
        color: #fff;
        position: relative;
        top: 200px;
        left: 90px;
        font-weight: 300;
        font-size: 2em;
        padding: 0px;
    }    
}
.categories{
    width: 100%;
    margin: 0 auto;
    position: relative;
    top: -180px;
    z-index: 99;
    height: 100%;
}

.categories .category {
    color: #fff;
    padding: 10px;
}

.categories .category .card {
    text-align: center;
    width: 350px;
    height: 160px;
    border: solid 1px #333;
    border-radius: 5px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 24px;
    background-repeat: no-repeat;
    background-size: cover!important;
    background-position: center!important;
    position: relative;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 60%);
    overflow: hidden;
    line-height: 160px;
    margin: 0 auto;
}

.categories .category .card .wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.categories .category .card a {
    position: absolute;
    left: 0%;
    width: 100%;
    display: inline-block;
    background: rgba(0, 0, 0, .3);
    color: #fff;
    text-decoration: none;
}

.categories .category .card video {
    position: absolute;
    opacity: 0;
    left: 0;
    width: 100%;
    transition: all 1s;
    background: transparent;
}

.categories .category .card:hover video {
    opacity: 1;
}

@media (min-width: 1280px) {
    .categories{
        width: 100%;
        margin: 0 auto;
        position: relative;
        top: -80px;
        z-index: 99;
        height: 100%;
    }
    
    .categories .category {
        float: left;
    }
    
    .categories .category .card {
        width: 290px;
    }
    
    .categories .category .card .wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
    
    .categories .category .card a {
        position: absolute;
        left: 0%;
        width: 100%;
        display: inline-block;
        background: rgba(0, 0, 0, .3);
        color: #fff;
        text-decoration: none;
    }
    
    .categories .category .card:hover {
        -webkit-transform: scale(1.3);
                transform: scale(1.3);
        z-index: 9999;
        border: solid 2px #ddd;
    }
    
    .categories .category .card video {
        position: absolute;
        opacity: 0;
        left: 0;
        width: 100%;
        transition: all 1s;
        background: transparent;
    }
    
    .categories .category .card:hover video {
        opacity: 1;
    }
}
.activities {
    clear: both;
    z-index: 9;
}

.activities .title {
    font-family: 'Oswald';
    color: #fff;
    font-size: 24px;
    font-weight: 300;
}

/*VIEW*/
.activities .items {
    margin: 0 auto;
    width: 100%;
    position: relative;
    transition: all .3s;
    scroll-behavior: smooth;
}

.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

.activities.horizontal .items {
    display: block;
    white-space: nowrap;
    height: 100%;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.activities .item {
    float:left;
    margin: 10px 5px;
    height: 220px;
    width: 300px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    border: solid 1px #000;
    transition: all .3s;
}

.activities.horizontal .item {
    height: 170px;
    float: none;
}

.activities .item .image {
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    height: 100%;
    transition: all .3s;
}

.activities .item:hover{
    border: solid 1px #333;
    -webkit-transform: scale(1);
            transform: scale(1);
    z-index: 999;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, .9);
}

.activities.horizontal .item:hover{
    -webkit-transform: scale(1);
            transform: scale(1);
}

.activities .item:hover .image{
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

@-webkit-keyframes agrandar {
    from {-webkit-transform: scale(1.2);transform: scale(1.2)}
    to {-webkit-transform: scale(2);transform: scale(2)}
}

@keyframes agrandar {
    from {-webkit-transform: scale(1.2);transform: scale(1.2)}
    to {-webkit-transform: scale(2);transform: scale(2)}
}

.activities .item .content {
    background: rgba(0, 0, 0, .8);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9;
    top: 85%;
    left: 0;
    padding: 5px 10px;
    transition: all .3s;
}

.activities.horizontal .item .content {
    top: 80%;
}

.activities .item:hover .content{
    top: 0px;
}

.activities .item .content .title {
    color: #fff;
    margin-top: 0px;
    font-size: 1.1em;
    float: left;
    overflow: hidden;
    max-width: 57%;
    white-space: break-spaces;
}

.activities .item .content .calendar-icon {
    width: 18px;
    float: left;
    margin-right: 10px;
}

.activities .item .content .date {
    color: #fff;
    margin-top: 0px;
    font-size: .8em;
    float: right;
    margin-right: 20px;
    margin-top: 8px;
}

.activities .item .content .date.end {
    margin-top: 0px;
}

.activities .item .content .description {
    clear: both;
    color: #fff;
    margin: 0px;
    font-size: .8em!important;
    white-space: break-spaces;
    padding-right: 5px;
}

.activities .item .content .footer {
    position: absolute;
    bottom: 20px;
    width: 95%;
    padding: 0px;
    border: none;
    background: transparent;
}

.activities .item .content .footer .price {
    color: #fff;
    float: left;
    font-size: .9em;
    margin: 0px;
}

.activities .item .content .footer .more-info {
    color: #fff;
    float: right;
    margin-right: 20px;
    text-decoration: none;
    font-size: .9em;
}

.activities.horizontal .item .content .description,
.activities.horizontal .item .content .footer .price, 
.activities.horizontal .item .content .footer .more-info {
    font-size: .7em;
}

@media(min-width: 1280px) {
    .activities {
        margin-left: 0px;
    }
    
    .activities .item {
        margin: 5px;
        width: 350px;
    }
    .activities .item:hover{
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
    }

    .activities.horizontal .items {
        width: 1254px;
    }

    .activities.horizontal .item {
        float: none;
        display: inline-block;
        width: 300px;
    }
}
.container {
    position: absolute;
    width: 1260px;
    left: 50px;
}

.arrows {
    top: 0;
    bottom: 0;
    z-index: 999;
}

.prev, .next{
    width: 50px;
    z-index: 99;
    height: 155px;
    background: rgba(0, 0, 0, .7);
    line-height: 180px;
    opacity: .5;
    transition: all .3s;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3em;
}

.prev {
    display: none;
    left: 0;
}

.next {
    right: 0;
}

.prev:hover, .next:hover {
    opacity: 1;
}

.prev img {
    width: 24px;
    position: relative;
    top: 0px;
    left: 11px;
}

.next img {
    width: 24px;
    position: relative;
    top: 0px;
    left: 14px;
}
.activity{
    clear: both;
    padding: 0px 20px;
    position: relative;
    top: -80px;
    color: #ddd;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    z-index: 999;
}

.tab {
    overflow: hidden;
    border-bottom: solid 1px #333;
}

.tab a {
    display: inline-block;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    padding: 10px 0px;
    margin-right: 10px;
    color: #ddd;
    text-decoration: none;
    font-weight: 300;
    font-family: 'Oswald';
    font-size: 18px;
    border-bottom: solid 3px transparent;
}

.tab a.active {
    border-bottom: solid 3px #666;
}

.activity .content {
    margin: 0 auto;
    background: #000;
    padding: 20px;
    border-radius: 5px;
}

.activity .content img {
    display: block;
    height: auto;
    width: 100%;
    margin: 0 auto;
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 4px;
    padding: 2px;
    transition: all .3s;
    cursor: ponter;
}

/*.activity .content img:hover {
    transform: scale(1.3);
    padding: 0px;
    border-width: 0px;
    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, .8);
}*/

.activity .content h2 {
    font-family: 'Oswald';
    font-weight: 300;
}

.activity .title {
    font-weight: 300;
    font-size: 18px!important;
    font-family: 'Oswald';
    clear: both;
}

.activity .info {
    width: 100%;
}

.activity .info a {
    color: #ddd;
    text-decoration: none;
    margin-bottom: 10px;
}

.activity ul {
    padding-left: 20px;
}

.activity ul li {
    padding: 5px 0px;
}

.activity .interest {
    font-size: .9em;
    width: 300px;
}

.activity .interest a {
    text-decoration: none;
    word-break: break-all;
    color: #ddd;
}

.video video {
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.modal {
    display: none;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background-color: black;
}

.modal .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    transition: all 1s;
    width: 100px;
    height: 100px;
    padding: 5px!important;
}

.close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
}
  
.close:hover,
.close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
}

.slide-container {
    height: 100px;
    width: 100px;
    background-position: center!important;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    transition: all 1s;
}

@media (min-width: 1024px) {
    .tab a {
        margin-right: 30px;
        font-size: 24px;
    }
    .activity .content {
        width: 70%;
    }

    .activity .content img {
        width: 60%;
    }

    .modal {
        padding-top: 20px;
    }
}
.gallery {
    clear: both;
    margin-left: -10px;
}

.gallery-full .gallery {
    overflow: hidden;
}

.gallery .card {
    float: left;
    width: 278px;
    height: 150px;
    margin: 5px;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    background-position: center!important;
    position: relative;
    overflow: hidden;
}

.gallery-full .gallery .card {
    width: 100%;
    height: 300px;
}

.gallery .card.full-width {
    float: none;
    margin: 0 auto;
}

.gallery .card:hover {
    cursor: pointer;
}

.gallery .card .description {
    position: absolute;
    bottom: 0px;
    background: rgba(0, 0, 0, .8);
    color: #ddd;
    padding: 10px;
    width: 100%;
}


  
@media (min-width: 1280px) {
    .gallery {
        margin-left: 0px;
    }
}
body {
    background: #fff!important;
}

.admin-layout {
    height: 100%;
}

.form {
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
    border: solid 1px #ccc;
    border-radius: 5px;
    padding: 20px;
    background: #f3f3f3;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .2);
}

.form input {
    margin: 0px 5px;
    width: 30px;
    height: 35px;
    border: solid 1px #ccc;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 40px;
}

.form button {
    margin-left: 10px;
}

.loading {
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
}

.admin-header {
    background: #333;
    color: #fff;
    font-family: 'Roboto';
    padding: 20px;
    line-height: 70px;
    margin-bottom: 20px;
    overflow: hidden;
}

.admin-logo {
    width: 140px;
    float: left;
    margin-right: 20px;
    cursor: pointer;
}

.admin-logo img {
    width: 100%;
}

.modal-large {
    display: 'flex';
    flex-flow: 'column nowrap';
    align-items: 'stretch';
    width: 65%;
}

.modal-header {
    background-color: #0078d4;
    color: #fff;
    font-size: 22px;
    align-items: center;
    padding: 12px 12px 14px 24px;
    display: flex;
}

.modal-close {
    margin-left: auto;
    color: #fff;
}

.modal-close:hover {
    background-color: #0078d4;
    color: #fff;
}

.modal-content {
    padding: 24px;
    overflow-y: hidden;
}

.modal-content p {
    font-size: 16px;
}

.modal-content .icon {
    font-size: 36px;
    vertical-align: middle;
    margin-right: 20px;
}

.modal-footer {
    padding: 24px;
    text-align: right;
}

.modal-footer button {
    margin-left: 10px;
}

.admin-data .title {
    font-family: 'Oswald';
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    margin: 0px;
    float: left;
}

.admin-menu {
    float: right;
    position: relative;
    top: -20px;
}

.admin-menu ul {
    padding: 0px;
    list-style-type: none;
}

.admin-menu ul li {
    display: inline-block;
    padding: 10px;
}

.admin-menu ul li a {
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    font-size: .9em;
}

.gallery-item {
    float: left;
    width: 155px;
    margin: 5px;
}

.gallery-item .admin-image, .admin-image {
    height: 155px;
    width: 155px;
    background-position: center!important;
    background-size: cover!important;
    cursor: pointer;
}

.add-button {
    clear: both;
    display: block;
}

.add-image {
    height: 155px;
    width: 155px;
    text-align: center;
    line-height: 155px;
    background: #eee;
    border: solid 1px #ccc;
}

input[type='file'] {
    display: none;
}

.add-image button i {
    font-size: 24px;
}

.buttons {
    display: flex;
    align-items: center;
}

.buttons .title {
    display: block;
    flex: 0 1 100%;
}
.quienes-somos .image-bg {
    height: 400px;
    width: 100%;
    top: 0;
    left: 0;
    background-position: center!important;
    background-size: cover!important;
    background-repeat: no-repeat!important;
}

.quienes-somos .content {
    z-index: 9;
    color: #fff;
    background: rgba(0, 0, 0, .8);
    padding: 5px 40px;
}

.quienes-somos .title {
    font-family: 'Oswald';
    font-size: 3em;
}

.quienes-somos .video {
    text-align: center;
}

@media (min-width: 1280px) {
    .quienes-somos {
        height: 1500px;
    }

    .quienes-somos.full-height {
        height: 2050px;
    }
    
    .quienes-somos .image-bg {
        height: 1500px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-position: center!important;
        background-size: cover!important;
        background-repeat: no-repeat!important;
    }

    .quienes-somos.full-height .image-bg{
        height: 2100px;
    }
    
    .quienes-somos .content {
        position: relative;
        top: 200px;
        left: 50%;
        z-index: 9;
        color: #fff;
        background: rgba(0, 0, 0, .8);
        padding: 5px 40px;
        width: 70%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
    }
    
    .quienes-somos .title {
        font-family: 'Oswald';
        font-size: 3em;
    }
    
    .quienes-somos .video {
        text-align: center;
    }
}
