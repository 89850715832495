.text-container {
    width: 100%;
    height: 100%;
    position: relative;
    top: -40px;
}

.text-container .title {
    font-family: 'Oswald';
    color: #fff;
    position: relative;
    top: 120px;
    left: 30px;
    font-weight: 300;
    font-size: 3em;
}

.text-container .description {
    font-family: 'Roboto';
    color: #fff;
    position: relative;
    top: 100px;
    padding: 0px 30px;
    font-weight: 400;
    font-size: .9em;
    padding-right: 20px;
}

@media (min-width: 1280px) {
    .text-container {
        height: 200%;
        width: 60%;
        position: relative;
        top: -80px;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
    }
    
    .text-container .title {
        font-family: 'Oswald';
        color: #fff;
        position: relative;
        top: 250px;
        left: 90px;
        font-weight: 300;
        font-size: 5em;
    }
    
    .text-container .description {
        font-family: 'Roboto';
        color: #fff;
        position: relative;
        top: 200px;
        left: 90px;
        font-weight: 300;
        font-size: 2em;
        padding: 0px;
    }    
}