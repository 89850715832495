body {
    background: #fff!important;
}

.admin-layout {
    height: 100%;
}

.form {
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    border: solid 1px #ccc;
    border-radius: 5px;
    padding: 20px;
    background: #f3f3f3;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .2);
}

.form input {
    margin: 0px 5px;
    width: 30px;
    height: 35px;
    border: solid 1px #ccc;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 40px;
}

.form button {
    margin-left: 10px;
}

.loading {
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.admin-header {
    background: #333;
    color: #fff;
    font-family: 'Roboto';
    padding: 20px;
    line-height: 70px;
    margin-bottom: 20px;
    overflow: hidden;
}

.admin-logo {
    width: 140px;
    float: left;
    margin-right: 20px;
    cursor: pointer;
}

.admin-logo img {
    width: 100%;
}

.modal-large {
    display: 'flex';
    flex-flow: 'column nowrap';
    align-items: 'stretch';
    width: 65%;
}

.modal-header {
    background-color: #0078d4;
    color: #fff;
    font-size: 22px;
    align-items: center;
    padding: 12px 12px 14px 24px;
    display: flex;
}

.modal-close {
    margin-left: auto;
    color: #fff;
}

.modal-close:hover {
    background-color: #0078d4;
    color: #fff;
}

.modal-content {
    padding: 24px;
    overflow-y: hidden;
}

.modal-content p {
    font-size: 16px;
}

.modal-content .icon {
    font-size: 36px;
    vertical-align: middle;
    margin-right: 20px;
}

.modal-footer {
    padding: 24px;
    text-align: right;
}

.modal-footer button {
    margin-left: 10px;
}

.admin-data .title {
    font-family: 'Oswald';
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    margin: 0px;
    float: left;
}

.admin-menu {
    float: right;
    position: relative;
    top: -20px;
}

.admin-menu ul {
    padding: 0px;
    list-style-type: none;
}

.admin-menu ul li {
    display: inline-block;
    padding: 10px;
}

.admin-menu ul li a {
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    font-size: .9em;
}

.gallery-item {
    float: left;
    width: 155px;
    margin: 5px;
}

.gallery-item .admin-image, .admin-image {
    height: 155px;
    width: 155px;
    background-position: center!important;
    background-size: cover!important;
    cursor: pointer;
}

.add-button {
    clear: both;
    display: block;
}

.add-image {
    height: 155px;
    width: 155px;
    text-align: center;
    line-height: 155px;
    background: #eee;
    border: solid 1px #ccc;
}

input[type='file'] {
    display: none;
}

.add-image button i {
    font-size: 24px;
}

.buttons {
    display: flex;
    align-items: center;
}

.buttons .title {
    display: block;
    flex: 0 100%;
}