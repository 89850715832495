.container {
    position: absolute;
    width: 1260px;
    left: 50px;
}

.arrows {
    top: 0;
    bottom: 0;
    z-index: 999;
}

.prev, .next{
    width: 50px;
    z-index: 99;
    height: 155px;
    background: rgba(0, 0, 0, .7);
    line-height: 180px;
    opacity: .5;
    transition: all .3s;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3em;
}

.prev {
    display: none;
    left: 0;
}

.next {
    right: 0;
}

.prev:hover, .next:hover {
    opacity: 1;
}

.prev img {
    width: 24px;
    position: relative;
    top: 0px;
    left: 11px;
}

.next img {
    width: 24px;
    position: relative;
    top: 0px;
    left: 14px;
}