.activity{
    clear: both;
    padding: 0px 20px;
    position: relative;
    top: -80px;
    color: #ddd;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    z-index: 999;
}

.tab {
    overflow: hidden;
    border-bottom: solid 1px #333;
}

.tab a {
    display: inline-block;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    padding: 10px 0px;
    margin-right: 10px;
    color: #ddd;
    text-decoration: none;
    font-weight: 300;
    font-family: 'Oswald';
    font-size: 18px;
    border-bottom: solid 3px transparent;
}

.tab a.active {
    border-bottom: solid 3px #666;
}

.activity .content {
    margin: 0 auto;
    background: #000;
    padding: 20px;
    border-radius: 5px;
}

.activity .content img {
    display: block;
    height: auto;
    width: 100%;
    margin: 0 auto;
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 4px;
    padding: 2px;
    transition: all .3s;
    cursor: ponter;
}

/*.activity .content img:hover {
    transform: scale(1.3);
    padding: 0px;
    border-width: 0px;
    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, .8);
}*/

.activity .content h2 {
    font-family: 'Oswald';
    font-weight: 300;
}

.activity .title {
    font-weight: 300;
    font-size: 18px!important;
    font-family: 'Oswald';
    clear: both;
}

.activity .info {
    width: 100%;
}

.activity .info a {
    color: #ddd;
    text-decoration: none;
    margin-bottom: 10px;
}

.activity ul {
    padding-left: 20px;
}

.activity ul li {
    padding: 5px 0px;
}

.activity .interest {
    font-size: .9em;
    width: 300px;
}

.activity .interest a {
    text-decoration: none;
    word-break: break-all;
    color: #ddd;
}

.video video {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.modal {
    display: none;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background-color: black;
}

.modal .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    transition: all 1s;
    width: 100px;
    height: 100px;
    padding: 5px!important;
}

.close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
}
  
.close:hover,
.close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
}

.slide-container {
    height: 100px;
    width: 100px;
    background-position: center!important;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    transition: all 1s;
}

@media (min-width: 1024px) {
    .tab a {
        margin-right: 30px;
        font-size: 24px;
    }
    .activity .content {
        width: 70%;
    }

    .activity .content img {
        width: 60%;
    }

    .modal {
        padding-top: 20px;
    }
}