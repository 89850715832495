.header {
    position: relative;
    padding: 0px;
}

.logo {
    position: absolute;
    top: 20px;
    left: 28px;
    z-index: 999;
    cursor: pointer;
}

.logo img {
    width: 100px;
}

.menu {
    position: absolute;
    right: 0px;
    z-index: 99;
    top: 20px;
}

.menu a {
    display: block;
    color: #fff;
    font-family: 'Roboto';
    text-decoration: none;
    font-size: .9em;
    padding: 15px;
    font-weight: bold;
    text-transform: uppercase;
    transition: all .3s;
    background: rgba(63, 157, 202, .5);
}

.menu a span{
  display: block;
  background: #fff;
  height: 1px;
  width: 20px;
  margin-bottom: 5px;
}

.menu a:hover {
    background: rgba(63, 157, 202, 1);
    color: #fff;
}

.search {
    position: absolute;
    right: 10px;
    z-index: 999;
    top: 8px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.search button {
    background: transparent;
    border:none;
    z-index: 99;
    margin-right: 20px;
}

.search img {
    width: 16px;
    position: relative;
    top: 3px;
}

.search input {
    background: transparent;
    border: none;
    width: 0px;
    padding: 20px 8px 20px 16px;
    transition: all .3s;
    color: #fff;
    font-size: 18px;
}

.search:hover {
    background: rgba(63, 157, 202, 1);
}

.search.active {
    background: rgba(63, 157, 202, 1);
}

.search:hover input {
    width: 300px;
    padding: 20px 8px 20px 16px;
}

.search input:focus {
    width: 300px;
    padding: 20px 8px 20px 16px;
    outline: none;
}

.lds-ring {
    position: relative;
    width: 80px;
    height: 300px;
    margin: 0 auto;
    padding-top: 300px;
}

.lds-ring.small {
  height: 50px;
  padding-top: 0px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #ccc;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ccc transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.loader {
    height: 600px;
    width: 100%;
    display: block;
    text-align: center;
    padding-top: 200px;
}

.loader img {
    width: 300px;
}

.lds-default {
    position: relative;
    width: 80px;
    height: 300px;
    margin: 0 auto;
    padding-top: 300px;
}
  .lds-default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }
  .lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }
  .lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }
  .lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }
  .lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }
  .lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }
  .lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }
  .lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }
  .lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }
  .lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }
  .lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }
  .lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }
  .lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }
  @keyframes lds-default {
    0%, 20%, 80%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
  
  .sidenav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 9999; /* Stay on top */
    top: 0; /* Stay at the top */
    right: 0;
    background-color: rgba(63, 157, 202, 1); /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  }
  
  /* The navigation menu links */
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-weight: 300;
    color: #fff;
    font-family: 'Oswald';
    display: block;
    transition: 0.3s;
  }
  
  /* When you mouse over the navigation links, change their color */
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  /* Position and style the close button (top right corner) */
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
@media (min-width: 1280px) {
    .loader {
        padding-top: 200px;
    }

    .logo {
        top: 20px;
        left: 28px;
    }
    
    .logo img {
        width: 250px;
    }

    .menu {
        top: 15px;
    }

    .search:hover input, .search input:focus {
        width: 400px;
    }
}