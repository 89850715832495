@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;500;700&family=Roboto:wght@100;400;500;700&display=swap');

html {
  overflow-x: hidden;
}

body {
  margin: 0px;
  padding: 0px;
  background: #000!important;
}

.layout {
  background: #000;
  max-width: 2000px;
  margin: 0 auto;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-2 {
  margin-bottom: 20px;
}

.ml-2 {
  margin-left: 20px;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

img.icon {
  width: 18px;
  position: relative;
  top: 5px;
  margin-right: 20px;
}

.activities-container {
  position: relative;
  top: -180px;
  padding: 0px 30px;
  z-index: 99;
}

.no-top {
  top: 0px;
}

.message {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

.clearfix {
  clear: both;
}

.separator {
  height: 1px;
  clear: both;
  width: 75%;
  margin: 0 auto;
}

.width-wrapper {
  position: relative;
  margin: 0 auto;
} 

.super-title {
  font-family: 'Oswald';
  margin: 20px 30px;
  border-bottom: solid 1px #000;
}

.mini-form {
  margin-left: 30px;
  width: 30%;
}

.medium-form {
  margin-left: 30px;
  width: 50%;
}

.block {
  display: block;
}

a {
  color: #ddd;
  text-decoration: none;
}

a[target='_blank']::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAADSSURBVChTfZE9CsJAEIUzS0A8hAjeIUfQTm3txcoj2Ik5go2CtW2009bOU3gAW0HIj99sYkjMxgeP+dn3lpld8f4gTdOAMM0ri0iyLDNwTtHLeyXOItLBdDHGdLWBLvyKl9D80MNwU5HmJbhhA9dFWQP9IXzGcbxKkuSlWnuTCyomHOHM9/2QsUbkkdNQFSO8UgeM9ia/Nwwc6MvtoBXbZv5SY00aBhbN4KAirsE5kpqKtIHWpdsgLLQg6j+cbMeNCdwy5r7603175MaDMQ8iknwAQrpd0CdufEEAAAAASUVORK5CYII=);
  margin: 0 3px 0 5px
}

.inline {
  display: inline-block;
}

.aligncenter {
  display: block;
  margin: 0 auto;
}

figure {
  margin-left: 0px;
  margin-right: 0px;
}

@media (min-width: 1280px) { 
  .width-wrapper {
    max-width: 1280px;
    width: 1280px;
  }

  .dk-ml-40 {
    margin-left: 40px;
  }

  .dk-ml-50 {
    margin-left: 50px;
  }

  .activities-container {
    top: -50px;
    padding: 0px 50px;
  }
}